import React, { Component } from 'react';
import styled from 'styled-components';
import { Header } from '../../components/index';
import { Heading2, Text, Heading3 } from '../../helper/Typography';
import { SeoDataComponent } from '@lws/react-components';
import { mapMetaObject } from '../../helper/helperServices';
import { Link } from 'gatsby';
import { Button } from '../../helper/StyledHelper';

interface EntryPageProps {
  pageContext: {
    generalTexts: any;
    routeTexts: any;
    locale: string;
    id: string;
  };
}

class About extends Component<EntryPageProps> {
  render() {
    const {
      generalTexts,
      routeTexts: imprint,
      locale,
    } = this.props.pageContext;
    return (
      <main className={'App Light'}>
        <SeoDataComponent data={mapMetaObject(imprint.meta).data} />
        <Header
          darkTheme={false}
          lang={locale}
          pathname={this.props.location.pathname}
          generalTexts={generalTexts}
        />
        <Container>{locale === 'de' ? <De /> : <En />}</Container>
      </main>
    );
  }
}

const De = () => (
  <>
    {' '}
    <Heading2>
      Impressum / <br />
      Pflichtangaben
    </Heading2>
    <Text.p>
      Angaben gemäß § 5 TMG
      <br />
      Neugelb Studios GmbH
      <br />
      Zeughofstraße 20
      <br />
      10997 Berlin
    </Text.p>
    <Text.p>
      Vertreten durch: <br />
      Thomas Kaiser <br />
      Hannes Kober
    </Text.p>
    <Text.p>Vorsitzender des Verwaltungsrats: Dominik Stöttner</Text.p>
    <Heading3>Kontakt</Heading3>
    <Text.p>Telefon: +49 69 26 95 86 54</Text.p>
    <Text.p>E-Mail: hello@neugelb.com</Text.p>
    <Heading3>Registereintrag und Umsatzsteuer-ID</Heading3>
    <Text.p>
      Eintragung im Handelsregister. Registergericht: Amtsgericht Berlin
      Charlottenburg <br />
      Registernummer: HRB 175622 B <br />
      Umsatzsteuer-Identifikationsnummer gemäß §27 a Umsatzsteuergesetz:
      DE306039509
    </Text.p>
    <Heading2 style={{ marginTop: '140px' }}>Compliance </Heading2>
    <Text.p>
      Der Commerzbank-Konzern bekennt sich in seinen Unternehmenswerten zu
      integrem Verhalten im Umgang miteinander und mit seinen Kunden. Wir setzen
      uns daher mit Nachdruck dafür ein, Betrug einerseits vorzubeugen und
      andererseits zu bekämpfen. Dies bedeutet auch, dass wir offen für Hinweise
      sind, die zur Aufklärung von wirtschaftskriminellen Handlungen führen.
      Auch gehen wir Hinweisen auf Gesetzesverstöße sowie Verstöße gegen
      regulatorische und interne Anforderungen konsequent nach.
    </Text.p>
    <Text.p>
      Zusätzlich zu den bewährten Kontaktwegen, zum Beispiel über die
      Compliance- oder Revisionsabteilungen, hat die Commerzbank mit dem
      Business Keeper Monitoring System (BKMSSystem) eine Plattform
      eingerichtet, über die Kunden, Mitarbeiter und Dritte online Hinweise in
      der Commerzbank-Gruppe geben können.
    </Text.p>
    <Heading3>Online Hinweise geben </Heading3>
    <Text.p>
      Sofern Sie auf Menschenrechtsverletzungen und/oder Umweltverstöße i.S.d.
      deutschen Lieferkettensorgfaltspflichtengesetzes (inklusive aber nicht
      beschränkt auf Kinder-/Zwangsarbeit, Arbeitsschutzverstöße, Missachtung
      der Koalitionsfreiheit, Diskriminierung, Vorenthaltung von gerechten
      Löhnen, die Zerstörung natürlicher Lebensgrundlagen, Landraub,
      Fehlverhalten von Sicherheitskräften sowie die Verbote der Herstellung,
      Verwendung und Behandlung von Quecksilber, persistenter organischer
      Schadstoffe und die Ausfuhr gefährlicher Abfälle), aufmerksam machen
      wollen, können Sie über den folgenden Button einen Hinweis an die
      Commerzbank AG geben.
    </Text.p>
    <ButtonSection>
      {' '}
      <a
        href="https://www.bkms-system.net/bkwebanon/report/clientInfo?cin=26kRfD&c=-1&language=ger"
        target="_blank"
      >
        <Button>Hinweis abgeben</Button>
      </a>
    </ButtonSection>
    <Heading2 style={{ marginTop: '140px' }}>
      Haftungsausschluss (Disclaimer)
    </Heading2>
    <Heading3>Haftung für Inhalte </Heading3>
    <Text.p>
      Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf
      diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis
      10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet,
      übermittelte oder gespeicherte fremde Informationen zu überwachen oder
      nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit
      hinweisen. Verpflichtungen zur Entfernung oder Sperrung der Nutzung von
      Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt.
      Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis
      einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von
      entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend
      entfernen.
    </Text.p>
    <Heading3>Haftung für Links </Heading3>
    <Text.p>
      Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren
      Inhalte wir keinen Einfluss haben. Deshalb können wir für diese fremden
      Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten
      Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten
      verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung
      auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum
      Zeitpunkt der Verlinkung nicht erkennbar. Eine permanente inhaltliche
      Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte
      einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von
      Rechtsverletzungen werden wir derartige Links umgehend entfernen.
    </Text.p>
    <Heading3>Urheberrecht</Heading3>
    <Text.p>
      Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen
      Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung,
      Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen
      des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen
      Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den
      privaten, nicht kommerziellen Gebrauch gestattet. Soweit die Inhalte auf
      dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte
      Dritter beachtet. Insbesondere werden Inhalte Dritter als solche
      gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung
      aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei
      Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend
      entfernen.
    </Text.p>
  </>
);

const En = () => (
  <>
    <Heading2>
      Imprint/
      <br />
      Mandatory disclosures
    </Heading2>
    <Text.p>
      Information according to § 5 TMG <br />
      Neugelb Studios GmbH <br />
      Zeughofstraße 20 <br />
      10997 Berlin
    </Text.p>
    <Text.p>
      Represented by <br />
      Thomas Kaiser <br />
      Hannes Kober
    </Text.p>
    <Text.p>
      Head of the Board of Directors (“Verwaltungsrats”): Dominik Stöttner
    </Text.p>

    <Heading3>Kontakt</Heading3>
    <Text.p>
      Phone: +49 69 26 95 86 54 <br />
      Mail: hello@neugelb.com
    </Text.p>

    <Heading3>Incorporation information and Tax-ID</Heading3>
    <Text.p>
      Registration in the commercial register: Amtsgericht Berlin Charlottenburg{' '}
      <br />
      ID: HRB 175622 B <br />
      Tax-ID („Umsatzsteuer-Identifikationsnummer“) according to §27 a
      Umsatzsteuergesetz: DE306039509
    </Text.p>
    <Heading2 style={{ marginTop: '140px' }}>Compliance</Heading2>
    <Text.p>
      One of Commerzbank’s core values is integrity in dealings with each other
      and with our clients. We are emphatically committed to detecting and
      preventing financial crime. Commerzbank wishes to encourage everyone to
      join the fight against financial crime by reporting suspicious behaviour.
      We also systematically investigate any indications of violations of the
      law or of regulatory and internal requirements.
    </Text.p>
    <Text.p>
      We have introduced a standardised reporting system, to run alongside our
      existing communication channels (the Compliance division and Internal
      Audit division). This Business Keeper Monitoring System (BKMS) is an
      internet-based application which enables customers, employees and third
      parties to report such activities that affect the Commerzbank Group
      online.
    </Text.p>
    <Heading3>Online Reporting</Heading3>
    <Text.p>
      If you wish to report human rights violations and/or environmental
      violations within the meaning of the German Supply Chain Act (including
      but not limited to child/forced labor, labor protection violations,
      disregard for freedom of association, discrimination, withholding of fair
      wages, destruction of natural resources, land grabbing, misconduct by
      security forces and prohibitions on the production, use and treatment of
      mercury, persistent organic pollutants and the export of hazardous waste),
      you can submit a report to Commerzbank AG via the following button.
    </Text.p>
    <ButtonSection>
      {' '}
      <a
        href="https://www.bkms-system.net/bkwebanon/report/clientInfo?cin=26kRfD&c=-1&language=eng"
        target="_blank"
      >
        <Button>Submit report</Button>
      </a>
    </ButtonSection>

    <Heading2 style={{ marginTop: '140px' }}>Disclaimer</Heading2>
    <Heading3>Limitation of liability for internal content</Heading3>
    <Text.p>
      The content of our website has been compiled with meticulous care and to
      the best of our knowledge. However, we cannot assume any liability for the
      up-to-dateness, completeness or accuracy of any of the pages.
    </Text.p>
    <Text.p>
      Pursuant to section 7, para. 1 of the TMG (Telemediengesetz – Tele Media
      Act by German law), we as service providers are liable for our own content
      on these pages in accordance with general laws. However, pursuant to
      sections 8 to 10 of the TMG, we as service providers are not under
      obligation to monitor external information provided or stored on our
      website. Once we have become aware of a specific infringement of the law,
      we will immediately remove the content in question. Any liability
      concerning this matter can only be assumed from the point in time at which
      the infringement becomes known to us.
    </Text.p>
    <Heading3>Limitation of liability for external links</Heading3>
    <Text.p>
      Our website contains links to the websites of third parties („external
      links“). As the content of these websites is not under our control, we
      cannot assume any liability for such external content. In all cases, the
      provider of information of the linked websites is liable for the content
      and accuracy of the information provided. At the point in time when the
      links were placed, no infringements of the law were recognisable to us. As
      soon as an infringement of the law becomes known to us, we will
      immediately remove the link in question.
    </Text.p>
    <Heading3>Copyright</Heading3>
    <Text.p>
      The content and works published on this website are governed by the
      copyright laws of Germany. Any duplication, processing, distribution or
      any form of utilisation beyond the scope of copyright law shall require
      the prior written consent of the author or authors in question
    </Text.p>
  </>
);

export const Container = styled.div`
  padding-top: 260px;
  padding-bottom: 260px;
  width: 640px;
  margin: auto;

  @media (max-width: 767px) {
    padding-top: 112px !important;
    padding-bottom: 112px !important;
    width: 95% !important;
  }
`;

export default About;

export const ButtonSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
